
// @ts-nocheck
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_en_js from "../lang/en.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_es_js from "../lang/es.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_fr_js from "../lang/fr.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_de_js from "../lang/de.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_pt_js from "../lang/pt.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_it_js from "../lang/it.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_nl_js from "../lang/nl.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_pl_js from "../lang/pl.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_jp_js from "../lang/jp.js";
import locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps__base_lang_en_ts from "../../_base/lang/en.ts";


export const localeCodes =  [
  "us",
  "uk",
  "au",
  "ca",
  "ie",
  "nz",
  "za",
  "es",
  "cl",
  "ar",
  "mx",
  "fr",
  "ca-fr",
  "be",
  "de",
  "ch",
  "ch-fr",
  "at",
  "pt",
  "br",
  "it",
  "nl",
  "be-nl",
  "pl",
  "jp",
  "en"
]

export const localeLoaders = {
  "us": [{ key: "../lang/en.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_en_js), cache: true }],
  "uk": [{ key: "../lang/en.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_en_js), cache: true }],
  "au": [{ key: "../lang/en.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_en_js), cache: true }],
  "ca": [{ key: "../lang/en.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_en_js), cache: true }],
  "ie": [{ key: "../lang/en.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_en_js), cache: true }],
  "nz": [{ key: "../lang/en.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_en_js), cache: true }],
  "za": [{ key: "../lang/en.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_en_js), cache: true }],
  "es": [{ key: "../lang/es.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_es_js), cache: true }],
  "cl": [{ key: "../lang/es.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_es_js), cache: true }],
  "ar": [{ key: "../lang/es.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_es_js), cache: true }],
  "mx": [{ key: "../lang/es.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_es_js), cache: true }],
  "fr": [{ key: "../lang/fr.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_fr_js), cache: true },
{ key: "../lang/fr.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_fr_js), cache: true }],
  "ca-fr": [{ key: "../lang/fr.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_fr_js), cache: true }],
  "be": [{ key: "../lang/fr.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_fr_js), cache: true }],
  "de": [{ key: "../lang/de.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_de_js), cache: true }],
  "ch": [{ key: "../lang/de.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_de_js), cache: true }],
  "ch-fr": [{ key: "../lang/fr.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_fr_js), cache: true }],
  "at": [{ key: "../lang/de.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_de_js), cache: true }],
  "pt": [{ key: "../lang/pt.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_pt_js), cache: true }],
  "br": [{ key: "../lang/pt.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_pt_js), cache: true }],
  "it": [{ key: "../lang/it.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_it_js), cache: true }],
  "nl": [{ key: "../lang/nl.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_nl_js), cache: true }],
  "be-nl": [{ key: "../lang/nl.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_nl_js), cache: true }],
  "pl": [{ key: "../lang/pl.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_pl_js), cache: true }],
  "jp": [{ key: "../lang/jp.js", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps_l4_lang_jp_js), cache: true }],
  "en": [{ key: "../../_base/lang/en.ts", load: () => Promise.resolve(locale__codebuild_output_src1478321061_src_bitbucket_org_magusa_cavu_workspaces_apps__base_lang_en_ts), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "us",
      "storyblok": "en",
      "lang": "English",
      "name": "United States",
      "language": "en-US",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      ]
    },
    {
      "code": "uk",
      "storyblok": "en",
      "lang": "English",
      "name": "United Kingdom",
      "language": "en-GB",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      ]
    },
    {
      "code": "au",
      "storyblok": "en",
      "lang": "English",
      "name": "Australia",
      "language": "en-AU",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      ]
    },
    {
      "code": "ca",
      "storyblok": "en",
      "lang": "English",
      "name": "Canada",
      "language": "en-CA",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      ]
    },
    {
      "code": "ie",
      "storyblok": "en",
      "lang": "English",
      "name": "Ireland",
      "language": "en-IE",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      ]
    },
    {
      "code": "nz",
      "storyblok": "en",
      "lang": "English",
      "name": "New Zealand",
      "language": "en-NZ",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      ]
    },
    {
      "code": "za",
      "storyblok": "en",
      "lang": "English",
      "name": "South Africa",
      "language": "en-ZA",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      ]
    },
    {
      "code": "es",
      "storyblok": "es",
      "lang": "Spanish",
      "name": "España",
      "language": "es-ES",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/es.js"
      ]
    },
    {
      "code": "cl",
      "storyblok": "es",
      "lang": "Spanish",
      "name": "Chile",
      "language": "es-CL",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/es.js"
      ]
    },
    {
      "code": "ar",
      "storyblok": "es",
      "lang": "Spanish",
      "name": "Argentina",
      "language": "es-AR",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/es.js"
      ]
    },
    {
      "code": "mx",
      "storyblok": "es",
      "lang": "Spanish",
      "name": "México",
      "language": "es-MX",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/es.js"
      ]
    },
    {
      "code": "fr",
      "storyblok": "fr",
      "lang": "French",
      "name": "France",
      "language": "fr-FR",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js",
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      ]
    },
    {
      "code": "ca-fr",
      "storyblok": "fr",
      "lang": "French",
      "name": "Canada",
      "language": "fr-CA",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      ]
    },
    {
      "code": "be",
      "storyblok": "fr",
      "lang": "French",
      "name": "Belgique",
      "language": "fr-BE",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      ]
    },
    {
      "code": "de",
      "storyblok": "de",
      "lang": "German",
      "name": "Deutschland",
      "language": "de-DE",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/de.js"
      ]
    },
    {
      "code": "ch",
      "storyblok": "de",
      "lang": "German",
      "name": "Schweiz",
      "language": "de-CH",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/de.js"
      ]
    },
    {
      "code": "ch-fr",
      "storyblok": "fr",
      "lang": "French",
      "name": "Suisse",
      "language": "fr-CH",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      ]
    },
    {
      "code": "at",
      "storyblok": "de",
      "lang": "German",
      "name": "Österreich",
      "language": "de-AT",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/de.js"
      ]
    },
    {
      "code": "pt",
      "storyblok": "pt",
      "lang": "Portuguese",
      "name": "Portugal",
      "language": "pt-PT",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/pt.js"
      ]
    },
    {
      "code": "br",
      "storyblok": "pt",
      "lang": "Portuguese",
      "name": "Brasil",
      "language": "pt-BR",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/pt.js"
      ]
    },
    {
      "code": "it",
      "storyblok": "it",
      "lang": "Italian",
      "name": "Italia",
      "language": "it-IT",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/it.js"
      ]
    },
    {
      "code": "nl",
      "storyblok": "nl",
      "lang": "Dutch",
      "name": "Nederland",
      "language": "nl-NL",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/nl.js"
      ]
    },
    {
      "code": "be-nl",
      "storyblok": "nl",
      "lang": "Dutch",
      "name": "België",
      "language": "nl-BE",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/nl.js"
      ]
    },
    {
      "code": "pl",
      "storyblok": "pl",
      "lang": "Polish",
      "name": "Polska",
      "language": "pl-PL",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/pl.js"
      ]
    },
    {
      "code": "jp",
      "storyblok": "jp",
      "lang": "Japanese",
      "name": "日本",
      "language": "jp-JP",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/jp.js"
      ]
    },
    {
      "code": "en",
      "lang": "English",
      "name": "United Kingdom",
      "language": "en-GB",
      "files": [
        "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/lang/en.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "lang/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {
    "index": false
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "fallbackLocale": "uk",
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "us",
    "storyblok": "en",
    "lang": "English",
    "name": "United States",
    "language": "en-US",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      }
    ]
  },
  {
    "code": "uk",
    "storyblok": "en",
    "lang": "English",
    "name": "United Kingdom",
    "language": "en-GB",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      }
    ]
  },
  {
    "code": "au",
    "storyblok": "en",
    "lang": "English",
    "name": "Australia",
    "language": "en-AU",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      }
    ]
  },
  {
    "code": "ca",
    "storyblok": "en",
    "lang": "English",
    "name": "Canada",
    "language": "en-CA",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      }
    ]
  },
  {
    "code": "ie",
    "storyblok": "en",
    "lang": "English",
    "name": "Ireland",
    "language": "en-IE",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      }
    ]
  },
  {
    "code": "nz",
    "storyblok": "en",
    "lang": "English",
    "name": "New Zealand",
    "language": "en-NZ",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      }
    ]
  },
  {
    "code": "za",
    "storyblok": "en",
    "lang": "English",
    "name": "South Africa",
    "language": "en-ZA",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/en.js"
      }
    ]
  },
  {
    "code": "es",
    "storyblok": "es",
    "lang": "Spanish",
    "name": "España",
    "language": "es-ES",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/es.js"
      }
    ]
  },
  {
    "code": "cl",
    "storyblok": "es",
    "lang": "Spanish",
    "name": "Chile",
    "language": "es-CL",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/es.js"
      }
    ]
  },
  {
    "code": "ar",
    "storyblok": "es",
    "lang": "Spanish",
    "name": "Argentina",
    "language": "es-AR",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/es.js"
      }
    ]
  },
  {
    "code": "mx",
    "storyblok": "es",
    "lang": "Spanish",
    "name": "México",
    "language": "es-MX",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/es.js"
      }
    ]
  },
  {
    "code": "fr",
    "storyblok": "fr",
    "lang": "French",
    "name": "France",
    "language": "fr-FR",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      },
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      }
    ]
  },
  {
    "code": "ca-fr",
    "storyblok": "fr",
    "lang": "French",
    "name": "Canada",
    "language": "fr-CA",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      }
    ]
  },
  {
    "code": "be",
    "storyblok": "fr",
    "lang": "French",
    "name": "Belgique",
    "language": "fr-BE",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      }
    ]
  },
  {
    "code": "de",
    "storyblok": "de",
    "lang": "German",
    "name": "Deutschland",
    "language": "de-DE",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/de.js"
      }
    ]
  },
  {
    "code": "ch",
    "storyblok": "de",
    "lang": "German",
    "name": "Schweiz",
    "language": "de-CH",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/de.js"
      }
    ]
  },
  {
    "code": "ch-fr",
    "storyblok": "fr",
    "lang": "French",
    "name": "Suisse",
    "language": "fr-CH",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/fr.js"
      }
    ]
  },
  {
    "code": "at",
    "storyblok": "de",
    "lang": "German",
    "name": "Österreich",
    "language": "de-AT",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/de.js"
      }
    ]
  },
  {
    "code": "pt",
    "storyblok": "pt",
    "lang": "Portuguese",
    "name": "Portugal",
    "language": "pt-PT",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/pt.js"
      }
    ]
  },
  {
    "code": "br",
    "storyblok": "pt",
    "lang": "Portuguese",
    "name": "Brasil",
    "language": "pt-BR",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/pt.js"
      }
    ]
  },
  {
    "code": "it",
    "storyblok": "it",
    "lang": "Italian",
    "name": "Italia",
    "language": "it-IT",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/it.js"
      }
    ]
  },
  {
    "code": "nl",
    "storyblok": "nl",
    "lang": "Dutch",
    "name": "Nederland",
    "language": "nl-NL",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/nl.js"
      }
    ]
  },
  {
    "code": "be-nl",
    "storyblok": "nl",
    "lang": "Dutch",
    "name": "België",
    "language": "nl-BE",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/nl.js"
      }
    ]
  },
  {
    "code": "pl",
    "storyblok": "pl",
    "lang": "Polish",
    "name": "Polska",
    "language": "pl-PL",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/pl.js"
      }
    ]
  },
  {
    "code": "jp",
    "storyblok": "jp",
    "lang": "Japanese",
    "name": "日本",
    "language": "jp-JP",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/lang/jp.js"
      }
    ]
  },
  {
    "code": "en",
    "lang": "English",
    "name": "United Kingdom",
    "language": "en-GB",
    "files": [
      {
        "path": "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/lang/en.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
